import { NavigationMenuItem, ResponsiveNavigationMenu } from '@troon/ui';
import { For, Match, Switch } from 'solid-js';
import { Title } from '@solidjs/meta';
import { FileRoutes } from '@solidjs/start/router';
import { ErrorBoundary } from '../../components/error-boundary';
import { Content } from '../../components/content';
import { Grid, GridQuarter, GridThreeQuarter } from '../../components/layouts/grid';
import type { RouteSectionProps } from '@solidjs/router';
import type { Entry } from '../../components/sidebar';

export default function Styleguide(props: RouteSectionProps) {
	const children = FileRoutes().find(({ path }) => path === '/_/styleguide')?.children as Array<{ path: string }>;
	const entries = children
		.sort((a, b) => sorter.compare(a.path, b.path))
		.slice(1)
		.reduce<Entry>(
			(items, { path }) => {
				const parts = path.split('/').slice(1);
				const lastPart = parts[parts.length - 1] as string;
				let entries = items.entries;
				if (parts.length > 1) {
					entries = items.entries!.find(({ slug }) => slug.endsWith(`/${parts[0]}`))!.entries;
				}

				entries!.push({
					title: `${lastPart.charAt(0).toUpperCase()}${lastPart.slice(1).replace(/-/g, ' ')}`,
					slug: `/_/styleguide${path}`,
					entries: [],
				});
				return items;
			},
			{ slug: '/_/styleguide', title: 'Style guide', entries: [] } satisfies Entry,
		);

	return (
		<Content>
			<Title>StyleGuide</Title>
			<Grid>
				<GridQuarter>
					<ResponsiveNavigationMenu trigger="Styleguide">
						<NavigationMenuItem href={entries.slug}>
							<b class="text-lg font-semibold">{entries.title}</b>
						</NavigationMenuItem>
						<For each={entries.entries}>
							{(item) => (
								<Switch>
									<Match when={item.entries?.length}>
										<For each={item.entries!}>
											{(item) => <NavigationMenuItem href={item.slug}>{item.title}</NavigationMenuItem>}
										</For>
									</Match>
									<Match when>
										<NavigationMenuItem href={item.slug}>{item.title}</NavigationMenuItem>
									</Match>
								</Switch>
							)}
						</For>
					</ResponsiveNavigationMenu>
				</GridQuarter>
				<GridThreeQuarter>
					<ErrorBoundary>{props.children}</ErrorBoundary>
				</GridThreeQuarter>
			</Grid>
		</Content>
	);
}

const sorter = new Intl.Collator('en');
